<template>
  <div id="weekly">
    <cent-title title="总体数据展示 - 周报" />
    <user-info></user-info>
  </div>
</template>

<script>
import centTitle from "../../components/content/centTitle.vue";
import UserInfo from "../../components/content/userInfo.vue";

export default {
  components: {
    centTitle,
    UserInfo,
  },
};
</script>

<style>
</style>